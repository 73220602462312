import Vue from "vue";
import VueRouter from "vue-router";
// import Colaborador from "../views/Colaborador.vue";
// import Clientes from "../views/Clientes.vue";
// import General from "../views/General.vue";
// import Promocional from "../views/Promocional.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/consulta",
  },
  // {
  //   path: "/colaborador/inicio",
  //   name: "Colaboradors",
  //   component: Colaborador,
  //   meta: {
  //     title: 'SOAP CENCOSUD', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP CENCOSUD'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP CENCOSUD'//open graph Descripcion metadata html
  //       }
  //     ]
  //   }  
  // },
  // {
  //   path: "/clientes/inicio",
  //   name: "Clientess",
  //   component: Clientes,
  //   meta: {
  //     title: 'SOAP CENCOSUD', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP CENCOSUD'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP CENCOSUD'//open graph Descripcion metadata html
  //       }
  //     ]
  //   }  
  // },
  // {
  //   path: "/general/inicio",
  //   name: "Generals",
  //   component: General,
  //   meta: {
  //     title: 'SOAP CENCOSUD', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP CENCOSUD'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP CENCOSUD'//open graph Descripcion metadata html
  //       }
  //     ]
  //   }  
  // },
  // {
  //   path: "/promocional/inicio",
  //   name: "Promocional",
  //   component: Promocional,
  //   meta: {
  //     title: 'SOAP CENCOSUD', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP CENCOSUD'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP CENCOSUD'//open graph Descripcion metadata html
  //       }
  //     ]
  //   }  
  // },
  // {
  //   path: "/colaborador/datos",
  //   name: "Colaborador",
  //   meta: {
  //     title: 'SOAP CENCOSUD', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP CENCOSUD'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP CENCOSUD'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/promocional/datos",
  //   name: "Promocionals",
  //   meta: {
  //     title: 'SOAP CENCOSUD', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP CENCOSUD'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP CENCOSUD'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/clientes/datos",
  //   name: "Clientes",
  //   meta: {
  //     title: 'SOAP CENCOSUD', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP CENCOSUD'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP CENCOSUD'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/general/datos",
  //   name: "General",
  //   meta: {
  //     title: 'SOAP CENCOSUD', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP CENCOSUD'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP CENCOSUD'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/colaborador/resumen",
  //   name: "Colaboradorss",
  //   meta: {
  //     title: 'SOAP CENCOSUD', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP CENCOSUD'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP CENCOSUD'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/clientes/resumen",
  //   name: "Clientesss",
  //   meta: {
  //     title: 'SOAP CENCOSUD', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP CENCOSUD'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP CENCOSUD'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/general/resumen",
  //   name: "Generalss",
  //   meta: {
  //     title: 'SOAP CENCOSUD', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP CENCOSUD'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP CENCOSUD'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/promocional/resumen",
  //   name: "Promocionalss",
  //   meta: {
  //     title: 'SOAP CENCOSUD', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP CENCOSUD'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP CENCOSUD'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/colaborador/finish",
  //   name: "Colaboradorsss",
  //   meta: {
  //     title: 'SOAP', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/clientes/finish",
  //   name: "Clientessss",
  //   meta: {
  //     title: 'SOAP', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/general/finish",
  //   name: "Generalsss",
  //   meta: {
  //     title: 'SOAP', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  // {
  //   path: "/promocional/finish",
  //   name: "Promocionalsss",
  //   meta: {
  //     title: 'SOAP', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  // },
  {
    path: "/consulta",
    name: "Consultar",
    meta: {
      title: 'SOAP CENCOSUD', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP CENCOSUD'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP CENCOSUD'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ConsultaView.vue"),
  },
  // {
  //   path: "/error",
  //   name: "Erorr",
  //   meta: {
  //     title: 'SOAP SEGUROFACIL', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP SEGUROFACIL'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP SEGUROFACIL'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../components/Error.vue"),
  // },
  // {
  //   path: "/info",
  //   name: "Info",
  //   meta: {
  //     title: 'SOAP SEGUROFACIL', //Titulo de la pagina
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'SOAP SEGUROFACIL'//Descripcion metadata html
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'SOAP SEGUROFACIL'//open graph Descripcion metadata html
  //       }
  //     ]
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../components/ErrorNote.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
//... Cambiar todos los fronts por router mas avanzado
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});


export default router;
